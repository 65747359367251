import { ME as CURRENT_USER_QUERY } from "./User";
import { useQuery } from "@apollo/react-hooks";

import { Spin } from "antd";

import dynamic from "next/dynamic";
import IsAllowed from "../common/routeGuardHelpers/isAllowed";
// import { useRouter } from "next/router";
// import { useEffect, useState } from "react";

// const AdminView = dynamic(import("./admin"));
// const EmpView = dynamic(import("./employer"));
// const GIGView = dynamic(import("./Gig"));
const AuthView = dynamic(import("./auth"));

const GuetView = dynamic(import("./guest"));

/**
 * @component
 * @description check for the role then return the appropriate component
 */
const DynamicView = (props) => {
  const { data, loading } = useQuery(CURRENT_USER_QUERY, {
    fetchPolicy: "cache-first",
  });
  // const router = useRouter();
  // const [wasLogin, setWasLogin] = useState(false);
  // useEffect(() => {
  //   // if (data?.me && !wasLogin) {
  //   //   setWasLogin(true);
  //   // }
  //   // if (data && !data.me && wasLogin) {
  //   //   setWasLogin(false);
  //   //   router.push("/signin");
  //   // }
  // }, [data]);

  if (loading)
    return <Spin size="large" className="large-loading absolute-loading" />;
  // console.log("Dynamic View ");
  return (
    <IsAllowed role={data?.me && data.me.role}>
      {data && data.me && data.me.role ? (
        <AuthView role={data?.me?.role} data={data}>
          {props.children}
        </AuthView>
      ) : (
        <GuetView>{props.children}</GuetView>
      )}
    </IsAllowed>
  );
};

export default DynamicView;
