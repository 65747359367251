import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

body{
  min-height:100%; padding:0; margin:0; position:relative;

}

SVG.S {
  transform:translate(18px, 0px) rotate(90deg);
}
SVG.GS {
  transform:translate(0px, -18px);

}
SVG.MS {
  transform:translate(-18px, 0px) rotate(275deg);
}
.name{
  text-transform:capitalize;
}

.date-style {

}

.wheel-fixed{
  height: 100vh;
  position: fixed;
  overflow: hidden;
}

::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
::-webkit-scrollbar-track {
    background: #f1f2f6;
    border-radius: 10px;
  }
::-webkit-scrollbar-thumb {
    background: rgb(116, 125, 140, 0.5);
    border-radius: 10px;
  }
.custom-tooltip{
   min-height:250px;
   opacity:1 !important;
   padding:0;
  min-width:250px; 
  display: flex;
    align-items: center;
    justify-content: center;
    /* &>div{
      zoom:.9
    } */
}

.request-custom-tooltip{
   /* min-height:250px; */
   opacity:1 !important;
   padding:0;
  /* min-width:250px;  */
  display: flex;
    align-items: center;
    justify-content: center;
    /* &>div{
      zoom:.9
    } */
}


.absolute-loading{
    background: white;
    position: absolute !important;
    opacity: 0.3;
    display: flex;
    align-items: center;
    justify-content: center;
    top:50%;
    left:50%;
    z-index:1000;
}

.overly-loading {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,.2);
    z-index:15;
    display:flex;
    align-items:center;
    justify-content:center;
    .ant-spin-dot {
      font-size:100px !important;
      width:55px;
      height:55px;
    .ant-spin-dot-item{
      width:25px;
      height:25px;
    }
    }
}

/* tables styles */
.ant-table-wrapper{
  margin-top:7px;
}

.ant-table {
  width: 100%;
max-width: 100%;
  color:#212529 !important;
  font-size:11px;
  position: relative;
  border-collapse: collapse;

margin-bottom: 1rem;
background-color: transparent;
font-family: 'Lato', sans-serif;
font-size:11px !important;


thead th {
        background-color: white;
        position: sticky;
        z-index:1000;
        top: 0;
        box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

  

  .ant-table-thead > tr > th {
  color: ${({ theme }) => theme.calendarPrimary ?? "black"} !important;
  background-color: white;

  font-size:12px;
  font-weight: normal;
  text-transform:capitalize;
  text-align:left;
  padding: 0px 5px !important;

} 

.ant-table-thead {
  .availability-success > span{
    color: green;
  }
  .availability-failed > span {
    color: red;
  }
}
.ant-table-row-cell-break-word{
  vertical-align:middle;

}
a {
    color: steelblue;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    -webkit-text-decoration-skip: objects;
}

}

.ant-table td {
  vertical-align: middle;
  text-align:left;
  padding:0.75rem;

}



.ant-table .ant-table-row:nth-child(2n) td {
background-color: #f9f8f8;
}
.ant-table-bordered .ant-table-tbody > tr > td {
  font-weight:normal
}

.admin-company-sites{
  padding:5px;
  .ant-table-content{
    tr > th {
     color:black;
     font-weight:400;
     box-shadow:none
    }
     .ant-table-tbody {
      .ant-table-row {
background-color:white;
      }
      .ant-table-row:nth-child(2n) td {
background-color: #f9f8f8;
}
     }
  }
}
.ant-table .ant-table-thead > tr > th.ant-table-column-has-actions {
position: sticky;
}

.suggestions-table {
  .ant-table-body {
    height: auto;
  }
}

.custom-table-styles{
  .ant-table-thead {
    tr {
      th {
      text-align:center;
    }
    }
    .ant-table-header-column{

    white-space: break-spaces;
    width: min-content;
    text-align: center;
   
    }
  }
 
}

.ant-table-footer {
  padding: 8px !important;
}
/*************************/

/**description styles */
.ant-descriptions-bordered .ant-descriptions-view {

  overflow-x: auto;
  color: #212529;
  table-layout: fixed ;
  width: 100% ;
}

.ant-descriptions-row {

  font-size:12px;
  :nth-child(odd) th  {
    background-color: white;

  }
  :nth-child(even) td {
    background-color:  #f9f8f8;
   

  }
  .ant-descriptions-item-label,.ant-descriptions-item-content {
    width:50%;
    font-size:12px;
    padding: 0.5rem !important; 
    color: black;
    text-align:left ; 

  }
  
  td {
    font-size:12px;
  }
}

.ant-descriptions-bordered .ant-descriptions-item-content {
  font-weight:normal;
}

.ant-descriptions-title {
  text-align:center;
  /* font-weight:normal !important; */
  color: black !important;
}
/***ANT checkbox */
.ant-checkbox-wrapper {
  font-size: 11px !important;
}
.sign-up-skills {
  font-size:14px !important;
}




/***********/

.container{

  padding: 0 10% 0 10%;
}

.fc-toolbar {
  flex-wrap: wrap;
}
/* .fc-view{
  margin-top:50px;
} */

.large-loading {
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }



.ant-carousel .slick-slide {
  text-align: center;
  line-height: 2;
  overflow: hidden;
}

.ant-carousel .slick-slide h3 {
  color: red;
}
.slick-dots li {
  background-color: #e01d25;
}

.selected {
  border: 1px solid white !important;
  background: ${({ theme }) => theme.eventSelected ?? "#fc9521"} !important;
  /* padding:5px !important; */
   
}

.cancelled {
  border: 1px solid white !important;
  background: ${({ theme }) => theme.eventCancelled ?? "#e60000"} !important;
  text-decoration: line-through;
  /* padding:5px !important; */
  
   
}

.requested {
  border: 1px solid white !important;
  background: ${({ theme }) => theme.eventRequested ?? "#ffffd5"} !important;
  /* padding:5px !important; */
  .fc-event-main{
    overflow:hidden;
    color: black !important;

  }
   
}



.booked {
  border: 1px solid white !important;
  background: ${({ theme }) => theme.eventBooked ?? "yellowgreen"} !important;
  /* padding:5px !important; */
   
}
.declined {
  border: 1px solid white !important;
    background:  ${({ theme }) =>
    theme.eventDeclined ?? "#585858"} !important;
    /* padding:5px !important; */
   
}
/* .completed {
    padding: 0 2px !important;
} */

.canceled-request-calendar {
  border: 1px solid white !important;
  background: #dddddd !important;
  .fc-event-main{
    overflow:hidden;
    color: black !important;

  }
}

.fc-event-main{
    overflow:hidden;


  }


.fc {
  width: 100%;
  margin-top: 10px;
}

.fc-dayGridMonth-button , .fc-dayGridWeek-button,  .fc-dayGridDay-button , .fc-listWeek-button,.fc-timeGridWeek-button,.fc-timeGridDay-button
 {
  color: ${({ theme }) => theme.calendarPrimary ?? "#fc9521"} !important;
    border: 2px solid ${({ theme }) =>
    theme.calendarPrimary ?? "#fc9521"} !important;
    border-radius: 0 !important;
    background: #fff !important ;
    padding: 7px 15px !important;
    box-shadow: none !important;
    height: auto !important;
    width: auto !important;
    font-size: 12px !important;
    text-transform: capitalize !important;
    margin: 0 !important;
    outline: 0 !important;
}

.fc-next-button, .fc-prev-button, .fc-today-button.fc-button {
    border: 2px solid #fff !important;
    border-radius: 0 !important;
    padding: 8px 15px !important;
    box-shadow: none !important;
    height: auto !important;
    width: auto !important;
    font-size: 14px !important;
    text-transform: capitalize;
    margin: 0 !important;
    outline: 0 !important;
    background: ${({ theme }) =>
    theme.calendarPrimary ?? "#fc9521"} !important;
    color: #fff !important;
}

.fc-button-active {
  background:${({ theme }) => theme.calendarPrimary ?? "#fc9521"} !important;
    color: #fff !important;
}

.fc-center h2,.fc-toolbar-title {
  font-size: 14px !important;
    background: ${({ theme }) => theme.calendarPrimary ?? "#fc9521"};
    color: white;
    padding: 6px 10px;
    border: 1px solid #eee;
    border-radius: 20px
}
.fc-daygrid-day-number, .fc-col-header-cell-cushion {
  color:#786a64;
  cursor:default;
}
.fc-event-title {
  text-transform:capitalize
}
/**ANT RATE */

.ant-rate{

  @media (max-width: 768px) {
    font-size:24px !important;
    .ant-rate-star:not(:last-child) {
      margin:2px !important;
    }
  }
}

.white-color-img {
  filter:invert(100%) sepia(5%) saturate(7500%) hue-rotate(265deg) brightness(108%) contrast(101%);

}
.ant-tabs-bar {
    margin: 8px !important;
  }
  
  .ant-fullcalendar-content {
    top:-3px;
    left:5px
  }

  //book-modal
  .book-modal-container {
    .ant-modal-confirm-btns{
      width: 100%;
      text-align: center;
    }
  }

  /// HERE MPAS STYLES
  .H_ib_body{
    background: rgba(100, 10, 10, 0.7);
color: white;
font-weight: bold;
  }

/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2/3) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"], 
    input[type="tel"], input[type="url"]{ font-size: 16px; }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="tel"], input[type="url"]{ font-size: 16px; }
}
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: ${({ theme }) => theme.calendarPrimary};
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 5px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px ${({ theme }) => theme.calendarPrimary}, 0 0 5px ${(
      { theme }
    ) => theme.calendarPrimary};
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: ${({ theme }) => theme.calendarPrimary};
  border-left-color: ${({ theme }) => theme.calendarPrimary};
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ant-table-body {
  max-height: calc(100vh - 300px);
}
.ant-table-thead th {
  background: white !important;
  overflow-wrap: break-word;
}



`;
export const theme = {
  main: "#e01d25",
  second: "#21273c",
  red: "#1b2836",
  black: "#393939",
  grey: "#3A3A3A",
  lightgrey: "#536976",
  offWhite: "#EDEDED",
  sky: "#eaeaed",

  maxWidth: "1200px",

  xs: "0px",
  sm: "576px",
  md: "767.98px",
  lg: "992px",
  xl: "1200px",

  bs: "0 12px 24px 0 rgba(0, 0, 0, 0.09)",
};
