import React, { useContext, useEffect } from "react";
import { useRouter } from "next/router";
import NProgress from "nprogress";
import Router from "next/router";
import {
  useMutation,
  useQuery,
  useSubscription,
  useLazyQuery,
} from "@apollo/react-hooks";
import { ME as CURRENT_USER_QUERY, BE_ADMIN } from "./User";
import { Button, Modal, notification as antNotification } from "antd";
import { withApollo } from "../../lib/withApollo";
import { Context } from "../common/dataContext/dataContext";

import DynamicView from "./dynamicView";
import Head from "next/head";
import gql from "graphql-tag";

const { confirm } = Modal;

const NOTIFICATION_SUBSCRIPTION = gql`
  subscription NOTIFICATION_SUBSCRIPTION($channels: [String!]!) {
    notification(channels: $channels) {
      id
      message
      channel
      createdAt
    }
  }
`;

export const MARK_LAST_SEEN = gql`
  mutation MARK_LAST_SEEN($id: Int!) {
    markLastNotificationSeen(id: $id) {
      message
    }
  }
`;

export const MISSED_NOTIFICATIONS = gql`
  query MISSED_NOTIFICATIONS($channel: String!) {
    missedNotifications(channel: $channel) {
      id
      message
      channel
      createdAt
    }
  }
`;

export const MISSED_NOTIFICATIONS_COUNT = gql`
  query MISSED_NOTIFICATIONS_COUNT($channel: String!) {
    missedNotificationsCount(channel: $channel)
  }
`;

const LAST_COMMIT_ID = gql`
  query LAST_COMMIT_ID {
    lastCommitID
  }
`;

Router.onRouteChangeStart = () => {
  NProgress.start();
};
Router.onRouteChangeComplete = () => {
  NProgress.done();
};

Router.onRouteChangeError = () => {
  NProgress.done();
};

const Page = (props) => {
  const r = useRouter();
  const { appState, addRole, setMissedNotifications, addSuperRole } =
    useContext(Context);

  const { data: currentUserData, refetch } = useQuery(CURRENT_USER_QUERY, {
    onCompleted: async (data) => {
      if (data?.me?.role) {
        const missedList = [
          "admin_messages",
          `ROLE_${data.me.role === "SUPER_ADMIN" ? "ADMIN" : currentUserData?.me?.role
          }`,
        ];
        addRole(
          data.me.role === "SUPER_ADMIN" ? "ADMIN" : currentUserData?.me?.role
        );
        addSuperRole(data.me.role === "SUPER_ADMIN");
        for (let i = 0; i < missedList.length; i++) {
          await refetchMissedNotifications({ channel: missedList[i] }).then(
            ({ data }) => {
              if (data.missedNotificationsCount > 0) {
                setMissedNotifications(
                  missedList[i],
                  data.missedNotificationsCount
                );
              }
            }
          );
        }
      }
    },
  });
  //for commands

  const { data: subData, loading: subLoading } = useSubscription(
    NOTIFICATION_SUBSCRIPTION,
    {
      variables: {
        channels: ["admin_commands"],
      },
      skip: !currentUserData || currentUserData.me === null,
    }
  );
  //for admin messages
  const { data: adminMessages, loading: adminMessagesLoading } =
    useSubscription(NOTIFICATION_SUBSCRIPTION, {
      variables: {
        channels: [
          "admin_messages",
          `ROLE_${currentUserData?.me?.role === "SUPER_ADMIN"
            ? "ADMIN"
            : currentUserData?.me?.role
          }`,
        ],
      },
      skip: !currentUserData || currentUserData.me === null,
    });

  const [markLastSeen, { loading: lastSeenLoading }] =
    useMutation(MARK_LAST_SEEN);

  const {
    data: missedNotifications = {},
    loading: missedNotificationsLoading,
    refetch: refetchMissedNotifications,
  } = useQuery(MISSED_NOTIFICATIONS_COUNT, {
    skip: true,
  });

  const {
    data: missedNotificationsCommands,
    loading: missedNotificationsCommandsLoading,
  } = useQuery(MISSED_NOTIFICATIONS, {
    variables: { channel: "admin_commands" },
    skip: !currentUserData || currentUserData.me === null,
  });

  const { data = {}, refetch: refetchLastCommit } = useQuery(LAST_COMMIT_ID, {
    skip: true,
  });

  // //for messages
  // useEffect(() => {
  //   if (
  //     missedNotifications &&
  //     missedNotifications.missedNotifications.length > 0
  //   ) {
  //     const lastMessage =
  //       missedNotifications.missedNotifications[
  //         missedNotifications.missedNotifications.length - 1
  //       ];

  //     antNotification.info({
  //       key: "justOneNotification",
  //       message: `HapiGig Notification!`,
  //       description: lastMessage.message,
  //       placement: "bottomRight",
  //       duration: 10,
  //     });

  //     markLastSeen({ variables: { id: lastMessage.id } });
  //   }
  // }, [missedNotifications]);

  //for Commands
  useEffect(() => {
    if (
      missedNotificationsCommands &&
      missedNotificationsCommands.missedNotifications.length > 0
    ) {
      const lastMessage =
        missedNotificationsCommands.missedNotifications[
        missedNotificationsCommands.missedNotifications.length - 1
        ];

      refetchLastCommit().then(({ data }) => {
        if (data.lastCommitID !== process.env.NEXT_PUBLIC_LAST_COMMIT_ID) {
          showConfirm(lastMessage.message, true);
        }
        markLastSeen({ variables: { id: lastMessage.id } });
      });
    }
  }, [missedNotificationsCommands]);

  const [be, beProps] = useMutation(BE_ADMIN, {
    refetchQueries: [{ query: CURRENT_USER_QUERY }],
    // awaitRefetchQueries: true,
    onCompleted: async (data) => {
      r.push(
        {
          pathname: `${appState.switch_to ? appState.switch_to : "/users/worker"
            }`,

          query: appState.switchToQuery,
        },
        `${appState.switch_to ? appState.switch_to : "/users/worker"}`,
        { shallow: true }
      );
    },
  });

  //Realtime commands
  useEffect(() => {
    if (subData) {
      refetchLastCommit().then(({ data }) => {
        if (data.lastCommitID !== process.env.NEXT_PUBLIC_LAST_COMMIT_ID) {
          showConfirm(subData.notification.message, true);
        }
        markLastSeen({ variables: { id: subData.notification.id } });
      });
    }
  }, [subData, subLoading]);

  //Realtime notification
  useEffect(() => {
    if (adminMessages) {
      antNotification.info({
        key: "justOneNotification",
        message: `${process.env.NEXT_PUBLIC_APP_NAME} Notification!`,
        description: adminMessages.notification.message,
        placement: "bottomRight",
        duration: 10,
      });
      markLastSeen({ variables: { id: adminMessages.notification.id } });
    }
  }, [adminMessages, adminMessagesLoading]);

  function showConfirm(message, refresh) {
    confirm({
      title: process.env.NEXT_PUBLIC_APP_NAME,
      content: message,
      okText: refresh ? "Refresh Page" : "Ok",
      okButtonProps: {
        disabled: !refresh,
      },
      onOk() {
        refresh ? Router.reload() : console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  const isBrowser = typeof window !== "undefined";

  return (
    <>
      <Head>
        <title>{process.env.NEXT_PUBLIC_APP_NAME}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
        />
      </Head>
      <DynamicView>{props.children} </DynamicView>

      {isBrowser && localStorage.getItem("sec") && (
        <Button
          type="primary"
          style={{ position: "fixed", top: 10 }}
          loading={beProps.loading}
          onClick={async () => {
            // await beProps.client.cache.reset();

            localStorage.removeItem("sec");

            r.push(
              {
                pathname: `${appState.switch_to ? appState.switch_to : "/users/worker"
                  }`,

                query: appState.switchToQuery,
              },
              `${appState.switch_to ? appState.switch_to : "/users/worker"}`,
              { shallow: true }
            ).then(() => refetch());
          }}
        >
          back admin
        </Button>
      )}
    </>
  );
};

export default withApollo({ ssr: false })(Page);
