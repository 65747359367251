import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import fetch from "isomorphic-unfetch";
import { ApolloLink, split } from "apollo-link";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { message } from "antd";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import Router from "next/router";
import jwt from "jsonwebtoken";
import Cookies from "js-cookie";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import { v4 as uuid4 } from "uuid";
import * as Sentry from "@sentry/nextjs";

export default function createApolloClient(
  initialState,
  ctx,
  userToken,
  setAuthToken
) {
  const ssrMode = !process.browser;
  const token = Cookies.get("token");
  const httpLink = new HttpLink({
    credentials: "include",
    fetch, // Switches between unfetch & node-fetch for client & server.
    uri: process.env.NEXT_PUBLIC_BACK_ENDPOINT,
  });

  const contextLink = setContext(async (_, { headers }) => {
    let sec = window ? localStorage?.getItem("sec") : undefined;
    const storedToken = Cookies.get("token");

    //check the token of the second if expired it will remove it and back to admin
    if (sec && jwt.decode(sec)?.exp * 1000 < Date.now()) {
      sec = undefined;
      localStorage.removeItem("sec");
    }
    return {
      headers: {
        ...ctx?.req?.headers,
        ...headers,
        authorization: storedToken ? `Bearer ${sec ? sec : storedToken}` : "",
        // frontVersion: process.env.NEXT_PUBLIC_LAST_COMMIT_ID,
        frontVersion: process.env.NEXT_PUBLIC_LAST_COMMIT_ID,
        "x-request-id": uuid4(),
      },
    };
  });
  const websocketProtocol = process.env.NEXT_PUBLIC_BACK_ENDPOINT.includes(
    "localhost"
  )
    ? "ws"
    : "wss";
  const wsLink = process.browser
    ? new WebSocketLink({
        uri: `${websocketProtocol}://${
          process.env.NEXT_PUBLIC_BACK_ENDPOINT.split("//")[1]
        }`,
        options: {
          reconnect: true,
          reconnectionAttempts: 50,
          lazy: true,
          timeout: 20000,
          connectionParams: async () => {
            const token = await Cookies.get("token");
            return {
              Authorization: `Bearer ${token}`,
            };
          },
          // connectionParams: {
          //   Authorization: `Bearer ${Cookies.get("token")}`,
          // },
        },
      })
    : null;

  const splitLink = process.browser
    ? split(
        // split based on operation type
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
          );
        },
        wsLink,
        httpLink
      )
    : httpLink;
  const initSentry = (operation) => {
    if (operation.getContext().headers) {
      let transactionId = operation.getContext().headers["x-request-id"];
      if (transactionId) {
        Sentry.configureScope((scope) => {
          scope.setTag("transaction_id", transactionId);
        });
      }
      let currentUserEmail = localStorage.getItem("email");
      if (currentUserEmail) {
        Sentry.setUser({ email: currentUserEmail });
      }
    }
    if (operation.operationName) {
      Sentry.configureScope((scope) => {
        scope.setTag("operationName", operation.operationName);
      });
    }
    if (operation.variables) {
      let variables = { ...operation.variables };
      if (variables.password) {
        delete variables.password;
      }
      Sentry.configureScope((scope) => {
        scope.setExtra("variables", variables);
      });
    }
  };
  const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
    initSentry(operation);
    if (graphQLErrors) {
      console.log("TCL: createClient -> graphQLErrors", graphQLErrors);
      if (!ssrMode) {
        graphQLErrors.map(({ message: msg }) => {
          if (msg == "Context creation failed: jwt expired") {
            // Router.prefetch("/signin?tokenExpired=true");
            Router.push("/signin?tokenExpired=true", "/signin");
            Router.pathname !== "/signin" &&
              message.error("your session has expired please login again", 10);
          } else {
            message.error(msg, 10);
          }
        });
      }
    }
    if (networkError) {
      console.log("TCL: createClient -> networkError", networkError);
      if (!ssrMode) {
        // message.error(networkError.message, 10);
        //   if (networkError.message == "Context creation failed: jwt expired") {
        //     Router.push({
        //       pathname: "/signin",
        //     });
        //     message.error("your session has expired please login again");
        //   } else {
        //     message.error(networkError.message);
        //   }
      }
    }
  });

  const refreshLink = new TokenRefreshLink({
    accessTokenField: "newToken",
    // No need to refresh if token exists and is still valid
    isTokenValidOrUndefined: () => {
      // No need to refresh if we don't have a userId
      if (!token) {
        return true;
      }

      const storedToken = Cookies.get("token");

      // console.log(
      //   "is Valid",

      //   jwt.decode(storedToken)?.exp * 1000,
      //   storedToken && jwt.decode(storedToken)?.exp * 1000 > Date.now()
      // );
      // No need to refresh if token exists and is valid
      if (storedToken && jwt.decode(storedToken)?.exp * 1000 > Date.now()) {
        return true;
      }
    },
    fetchAccessToken: async () => {
      const storedToken = Cookies.get("token");

      if (!storedToken) {
        // no need to refresh if userId is not defined
        return null;
      }
      // Use fetch to access the refreshUserToken mutation

      const response = await fetch(`${process.env.NEXT_PUBLIC_BACK_ENDPOINT}`, {
        method: "POST",
        credentials: "include",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          query: `mutation {
            refreshAccessToken{
              token
              user{
                id
              }
                    }
                  }`,
        }),
      });
      const resToJson = await response.json();
      return resToJson;
    },
    handleFetch: (newToken) => {
      // save new authentication token to state

      setAuthToken(newToken);
    },
    handleResponse: (operation, accessTokenField) => (response) => {
      if (!response) return { newToken: null };
      return { newToken: response.data?.refreshAccessToken?.token };
    },
    handleError: (error) => {
      console.error("Cannot refresh access token:", error);
    },
  });

  let link = ApolloLink.from([errorLink, refreshLink, contextLink, splitLink]);

  // The `ctx` (NextPageContext) will only be present on the server.
  // use it to extract auth headers (ctx.req) or similar.
  return new ApolloClient({
    credentials: "include",
    ssrMode: Boolean(ctx),
    link,

    cache: new InMemoryCache().restore(initialState),
    defaultOptions: {
      query: {
        fetchPolicy: "network-only",
      },
      watchQuery: {
        fetchPolicy: "network-only",
      },
    },
  });
}
