import React, { useState, useEffect } from "react";

export const Context = React.createContext();

export const Provider = ({ children }) => {
  const [appState, setAppState] = useState({ maxWidth: false });

  const addToAppState = (name, data) => {
    setAppState((prv) => ({ ...prv, [name]: data }));
  };

  const toggleScreenSize = () => {
    setAppState((prv) => ({ ...prv, maxWidth: !prv.maxWidth }));
  };

  const resetScreenSize = () => {
    setAppState((prv) => ({ ...prv, maxWidth: false }));
  };
  useEffect(() => {
    setAppState(
      localStorage.getItem("appState")
        ? JSON.parse(localStorage.getItem("appState"))
        : {}
    );
  }, []);

  const addRole = (role) => {
    setAppState((prv) => ({ ...prv, role: role }));
  };
  const addSuperRole = (isSuper) => {
    setAppState((prv) => ({ ...prv, isSuper: isSuper }));
  };
  const setMissedNotifications = (channel, missed) => {
    setAppState((prv) => ({
      ...prv,
      notifications: { ...prv.notifications, [channel]: missed },
    }));
  };
  const resetContext = () => {
    setAppState({});
  };

  useEffect(() => {
    localStorage.setItem("appState", JSON.stringify(appState));
  }, [appState]);

  return (
    <Context.Provider
      value={{
        appState,
        addData: addToAppState,
        toggleScreenSize,
        resetScreenSize,
        addRole,
        addSuperRole,
        setMissedNotifications,
        resetContext,
      }}
    >
      {children}
    </Context.Provider>
  );
};
