import { createContext, useContext, useState, useEffect } from "react";
const BrandContext = createContext();

function BrandProvider({ children }) {
  const [brandData, setBrandData] = useState();


  //https://stackoverflow.com/questions/59838341/dynamic-import-files-with-react
  useEffect(() => {
    const loadData = async () => {
      const data = await import(`../brandConfig/${process.env.NEXT_PUBLIC_BRAND}/brand.js`);
      setBrandData(data.default);
    };

    loadData();
  }, []);

  const value = brandData;
  return (
    <BrandContext.Provider value={value}>{children}</BrandContext.Provider>
  );
}

function useBrand() {
  const context = useContext(BrandContext);
  if (context === undefined) {
    throw new Error("useBrand must be used within a BrandProvider");
  }
  return context;
}

export { BrandProvider, useBrand };
