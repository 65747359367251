import { theme, GlobalStyle } from "../components/layout/global.styles";
import { ThemeProvider } from "styled-components";
import Head from "next/head";
import Page from "../components/layout/Page";
import { Provider as DataProvider } from "../components/common/dataContext/dataContext";
import AuthProvider from "../components/common/authProvider/AuthProvider";
import { useEffect } from "react";
import { useRouter } from "next/router";
import "../styles/global.scss";
import { BrandProvider, useBrand } from "../utils/brandContext";
import { useState } from "react";
import { GoogleAnalytics } from "nextjs-google-analytics";

import useFouc from './use-fouc-fix';

//

const MyApp = (props) => {
  useFouc()
  const [brandTheme, setBrandTheme] = useState({});
  const { Component, pageProps } = props;
  const router = useRouter();
  // useEffect(() => {
  //   const handleRouteChange = (url) => {
  //     ga.pageview(url);
  //   };
  //   //When the component is mounted, subscribe to router changes
  //   //and log those page views
  //   router.events.on("routeChangeComplete", handleRouteChange);

  //   // If the component is unmounted, unsubscribe
  //   // from the event with the `off` method
  //   return () => {
  //     router.events.off("routeChangeComplete", handleRouteChange);
  //   };
  // }, [router.events]);

  useEffect(() => {
    const themeImport = async () => {
      const brand = await import(
        `../brandConfig/${process.env.NEXT_PUBLIC_BRAND}/brand.js`
      );
      setBrandTheme(brand.default);
    };
    themeImport();
  }, []);

  return (
    <>
      <Head>
        <title>{process.env.NEXT_PUBLIC_APP_NAME}</title>
        <meta
          name="description"
          content="We are on a mission to improve the lives of great warehouse workers across the country by connecting them with employers who are in need of high quality flex labor."
        />
        <meta charSet="utf-8" />
        <meta
          name="application-name"
          content={process.env.NEXT_PUBLIC_APP_NAME}
        />
        <meta
          name="apple-mobile-web-app-title"
          content={process.env.NEXT_PUBLIC_APP_NAME}
        />
      </Head>
      <GoogleAnalytics trackPageViews gaMeasurementId={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS} />
      <BrandProvider>
        <AuthProvider>
          <ThemeProvider theme={brandTheme}>
            <GlobalStyle />
            <DataProvider>
              <Page>
                <Component {...pageProps} />
              </Page>
            </DataProvider>
          </ThemeProvider>
        </AuthProvider>
      </BrandProvider>
    </>
  );
};

export default MyApp;
