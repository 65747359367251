import { createContext, useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const initialAuthState = {
    token: Cookies.get("token"),
    userId: undefined,
    email:undefined,
  };

  const [authState, setAuthState] = useState(initialAuthState);

  useEffect(() => {
    if (Cookies.get("token") !== authState.token) {
      console.log("HELLO");
      setAuthState({
        ...authState,
        token: Cookies.get("token"),
      });
    }
  }, [authState]);

  const signIn = (token, userId,email) => {
    Cookies.set("token", token);
    setAuthState({
      ...authState,
      userId,
      token,
      email
    });

    if (typeof window !== "undefined") {
      localStorage.setItem("userId", userId);
      localStorage.setItem("token", token);
      localStorage.setItem("email", email);
    }
  };

  const setAuthToken = (token) => {
    Cookies.set("token", token);
    localStorage.setItem("token", token);
    setAuthState({
      ...authState,
      token,
    });
  };

  const signOut = () => {
    setAuthState({
      token: undefined,
    });
    Cookies.remove("token");
    if (typeof window !== "undefined") {
      localStorage.removeItem("userId");
      localStorage.removeItem("token");
      localStorage.removeItem("sec");
    }
  };

  return (
    <AuthContext.Provider value={{ authState, setAuthToken, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider as default, useAuth };
