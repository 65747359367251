import gql from "graphql-tag";

const ME = gql`
  query {
    me {
      id
      firstName
      lastName
      email
      phone
      role
      smsNotifications
      emailNotifications
      avatar {
        id
        image
      }
      worker {
        id
        completedGigs
        weekWorkedHours
        gigCompletionPercentage
        avgRate
        homeCity
        homeState {
          id
          sName
        }
        homeStreet
        homeZipCode
        noCallNoShow
        responseRate
        channel {
          id
          name
        }
        state {
          id
          name
          sName
        }
        facilityName
        facilityStreet
        zipCode
        city
        position
        skills {
          id
          name
        }
        workPeriod {
          id
          name
        }
        user {
          firstName
          lastName
          id
          avatar {
            id
            image
          }
        }
      }
      employer {
        company {
          id
          name
          companyResourcesLink
        }
        internalSites {
          id
          name
        }
        id
        state {
          id
          name
          sName
        }
        sites {
          id
          name
          sName
        }
        facilityName
        facilityStreet
        zipCode
        city
        user {
          firstName
          lastName
          id
        }
      }
      admin {
        id
      }
    }
  }
`;
const BE_ADMIN = gql`
  mutation BE_ADMIN {
    beAdmin {
      message
    }
  }
`;
/**graphql mutation for be any USER  */
const BE_THIS_USER = gql`
  mutation BE_THIS_USER($id: Int!) {
    toAnyUser(id: $id) {
      token
      user {
        id
      }
    }
  }
`;
export { ME, BE_ADMIN, BE_THIS_USER };
