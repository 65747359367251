import React from "react";
import { useRouter } from "next/router";
const isAllowed = (props) => {
  const guestRoutes = [
    "/",
    "/signin",
    "/signup",
    "/signupworker",
    "/privacy-policy",
    "/terms-of-use",
    "/user-agreement",
    "/reset",
    "/thank-you",
    "/thank-you-worker",
    "/version",
  ];

  const router = useRouter();

  if (props.role || guestRoutes.indexOf(router.pathname) >= 0) {
    return <>{props.children}</>;
  } else {
    router.push({ pathname: "/signin", query: { from: router.asPath } });
    return <></>;
  }
};

export default isAllowed;
